import {Component, OnDestroy, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {NotificationService} from '../services/notification.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {SwalService} from '../services/swal.service';
import {AngularFireStorage} from '@angular/fire/storage';
import {AngularFireAuth} from '@angular/fire/auth';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {CocService} from '../services/coc.service';
import algoliasearch from 'algoliasearch';
declare let $: any;
export const searchClient = algoliasearch(
    '7TZ0K8FQ3F',
    'c4c2dd0095c854dc9bb8a2c0741da33c'
);

@Component({
    selector: 'app-store',
    templateUrl: './store.component.html',
    styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit, OnDestroy {
    public uid: string;
    public subs = [];


    mobile: boolean;
    private geysers: any;
    private amount: any;
    private sub: Subscription;
    private user: any;
    config = {
        indexName: 'submissions',
        searchClient,
    };
    filters: string = null;

    constructor(public afs: AngularFirestore,
                public toast: NotificationService,
                public spinner: NgxSpinnerService,
                public s: SwalService,
                public router: Router,
                public a: AuthService,
                private coc: CocService,
                public storage: AngularFireStorage,
                public afAuth: AngularFireAuth) {
        window.onresize = () => this.mobile = window.innerWidth <= 991;
        this.getCoc();

    }


    ngOnInit() {
        // window.onresize = () => this.mobile = window.innerWidth <= 991;


    }

    nav(sub: any) {

        this.router.navigate([sub.type + '/' + sub.objectID])
    }


    async getCoc() {

        this.user = await this.a.getUser()

        switch (this.user.type) {

            case 'Plumber':
                this.filters = `uid:${this.user.uid} AND status:approved`
                break;

            case 'admin':
                this.filters = 'status:approved'
                break;


            case 'Company':
                this.filters = `parent:${this.user.uid} AND status:approved`
                break;


            case 'insurance':
                this.filters = `insurance:${this.user.comp}`
                break;


            case 'manufacture':
                this.filters = this.filters = `manufacture:${this.user.comp}`
                break;

        }


    }

    ngOnDestroy(): void {


    }

    color(type) {
        switch (type) {

            case '/view-heat-pump':
                return '#53AC57';

            case '/view-solar':
                return '#E94643'

            case '/view-solarr':
                return '#FD9B19';

            case '/view-prisma':
                return '#A844BA'

            case '/view-electric':
                return '#1CBED2';

            case '/view-retro-fit':
                return 'rgba(114,7,7,0.96)';

            case '/view-gravity-fed':
                return 'rgba(36,134,100,0.96)';


        }

    }

    stringify(row: any) {
        return JSON.stringify(row)
    }


    navG() {
        //location.reload()
        searchClient.clearCache().then((r)=>{
            console.log(r)
        })

    }

    show(results: any) {
        console.log(results)
    }
}
