import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Howl} from 'howler';
import {first} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    static uid;
    static church;
    static user_name;
    public data: any = {};

    uid: string;
//  private uid: string;
    isVisible: boolean = true;
    sound: any;
    amount: any = 141;
    serviceFormAmount = 95
    gAmount: any = 95;
    baseUrl = 'https://qr.waccsa.co.za/'
    url = 'https://qr.waccsa.co.za'

    merchantKey = //'46f0cd694581a'; //'
        'lmmjxf2hwvivr';
    merchantID =
        '18104000';

    // '10000100'
//:

    liveUrl = //environment.production ?
        'https://www.payfast.co.za' //:'https://payfast.co.za';
    functionsUrl = //!environment.production ?
        // 'http://localhost:5001/wacc-test-env/us-central1/':
        'https://us-central1-waccsa-e8589.cloudfunctions.net/'
    emailCoc: any =
        // !environment.production ? 'http//localhost:5001/wacc-test-env/us-central1/sendMailCoc':
        'https://us-central1-waccsa-e8589.cloudfunctions.net/sendMailCoc'
    card_amount = 250;
    pendingPayment: Boolean;
    listing_amount: number = 250;


    constructor(
        private afAuth: AngularFireAuth,
        public http: HttpClient,
        public afs: AngularFirestore
    ) {
        this.sound = new Howl({

            src: ['https://firebasestorage.googleapis.com/v0/b/waccsa-e8589.appspot.com/o/quite-impressed-565.mp3?alt=media&token=06f27de1-b491-4406-a4d7-1036d047e7c1']
        });

    }

    isAuth() {
        this.afAuth.authState.subscribe(res => {
            return !!(res && res.uid);
        })

    }

    play() {
        this.sound.play()


    }


    emailLink(url, data) {
        if (data) {
            this.http.post(this.emailCoc,
                {
                    url: url,
                    email: data,

                }
            ).subscribe((res: any) => {

            })
        }
    }


    getUserId(): Promise<any> {
        return this.afAuth.authState.pipe(first()).toPromise();
    }

    async getUser() {
        const user = await this.getUserId();
        return this.afs.collection('users').doc(user.uid).valueChanges().pipe(first()).toPromise();


    }

    sendMail(data: any, url) {

        return this.http.post(this.functionsUrl + url, data)
    }


    getUsers(type) {
        return this.afs.collection('users', ref => ref.where('type', '==', type)
            .orderBy('id', 'desc'))
            .valueChanges().pipe(first()).toPromise();
    }

    openPayfast(amount: number, route: string, itemName: string, firstName, lastName: string, uid: string, cocType: string, email: string, coc: string) {

        sessionStorage.setItem('pp', `?type=${cocType}&name=${itemName}&route=${route}`)

        window.open(`${this.liveUrl}/eng/process?
                    amount=${amount}&
                    merchant_key=${this.merchantKey}&
                    merchant_id=${this.merchantID}&
                    item_name=${itemName}&
                    name_first=${firstName}&
                    name_last=${lastName}&
                    custom_str1=${uid}&
                    custom_str2=${coc}&
                    custom_str3=${route}&
                    email_address=${email}&
                    custom_str4=${amount}&
                    paraphrase=WACCsa123456&
                    return_url=${this.baseUrl}success/true&
                    cancel_url=${this.baseUrl}success/false&
                    notify_url=${this.functionsUrl}notify`
            , '_self')
    }

    getUserById(uid) {
        return this.afs.collection('users').doc(uid).valueChanges().pipe(first()).toPromise();

    }
}

// http://localhost:4200/success/true?type=%2Fview-general-certificate&route=IihHqvWEB5j2rkJEp66Y&name=General

