import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {
  private redirectUrl: string;
  private uid: any = null;


  constructor( private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


      if (localStorage.getItem('user_type') === 'admin') {


        return  true
      }
      else {

        this.router.navigate(['/submissions'])

        return false;
      }

  }
}
