<div class="main-content">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                 color="#fff"
                 fullScreen="true"
                 size="medium"
                 type="ball-clip-rotate-multiple">
        <p style="font-size: 20px; color: white"></p>
    </ngx-spinner>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header card-header-primary card-header-icon">
                        <div class="card-icon">
                            <i class="material-icons">assignment</i>
                        </div>
                        <h3 *ngIf="downloads" class="card-title text-center text-danger">({{downloads}}) -
                            Downloads</h3>
                    </div>
                    <div class="card-body">
                        <div class="toolbar">
                            <h3 *ngIf="mobile" class="text-center text-danger">Swipe sideways for more options</h3>
                            <div class="text-center p-4">
                                <button (click)="navR()" class="btn btn-primary btn-sm">Refresh</button>
                            </div>
                        </div>
                        <div class="material-datatables table-responsive">
                            <ais-instantsearch [config]="config">
                                <ais-configure [searchParameters]="{ hitsPerPage: 15 }"></ais-configure>

                                <ais-search-box
                                        placeholder="Search Downloads"></ais-search-box>
                                <ais-hits>
                                    <ng-template let-hits="hits" let-results="results">
                                        <div class="material-datatables p-1 table-responsive ">

                                            <table cellspacing="80" class="row-border hover " width="100%">
                                                <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Plumber</th>
                                                    <th>Type</th>
                                                    <th>Downloaded date</th>
                                                    <th class="disabled-sorting text-center">Message</th>
                                                    <th class="disabled-sorting text-center">Auditors</th>
                                                    <th class="disabled-sorting text-center">Status</th>
                                                    <th class="disabled-sorting text-center">View COC</th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                <tr *ngFor="let row  of hits;">
                                                    <td>
                                                        <ais-highlight [hit]="row"
                                                                       attribute="count">
                                                        </ais-highlight>
                                                    </td>
                                                    <td><a [routerLink]="'/profile/'+row?.uid">{{row?.user}}</a></td>
                                                    <td>{{row?.coc_type}}</td>
                                                    <td>{{row?.date|date:'d-MM-y'}}{{row?.count}}</td>
                                                    <td class="disabled-sorting text-center">
                                                        <mat-icon (click)="openDialog(row)" class="pointer"
                                                                  color="primary">mail
                                                        </mat-icon>
                                                    </td>
                                                    <td class="disabled-sorting text-center">
                                                        <mat-select
                                                                [(ngModel)]="row.auditor"
                                                                placeholder="Select auditor">
                                                            <mat-option *ngFor="let a of auditors" [value]="a">
                                                                {{a}}
                                                            </mat-option>
                                                        </mat-select>

                                                    </td>
                                                    <td class="disabled-sorting text-center">
                                                        <mat-select
                                                                (selectionChange)="change(row)"
                                                                [(ngModel)]="row.status"
                                                                placeholder="Select status">
                                                            <mat-option *ngFor="let i of statuses" [value]="i">
                                                                {{i}}
                                                            </mat-option>
                                                        </mat-select>

                                                    </td>
                                                    <td (click)="navG(row.type + '/' + row.objectID)"
                                                        class="disabled-sorting text-center pointer">
                                                        <mat-icon *ngIf="row?.refixed" color="primary">build</mat-icon>
                                                        <span class="text-primary"> View
                    </span>

                                                    </td>
                                                </tr>

                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="mat-footer-cell text-center text-primary semi-bold">
                                            <h4>{{results?.nbHits}}: Total
                                                Records </h4></div>

                                    </ng-template>
                                </ais-hits>
                                <ais-pagination></ais-pagination>
                            </ais-instantsearch>
                        </div>
                    </div>
                    <!-- end content-->
                </div>
                <!--  end card  -->
            </div>
            <!-- end col-md-12 -->
        </div>
        <!-- end row -->
    </div>
</div>
