<mat-dialog-content lyAlignContent="center" class="mat-typography">
  <p>Send Message</p>
  <mat-form-field appearance="fill">
    <mat-label>Type your message here</mat-label>
    <textarea rows="7" matInput [(ngModel)]="data.message"></textarea>
  </mat-form-field>
</mat-dialog-content>
<div class="mat-dialog-actions">
<!--  <button mat-button (click)="onNoClick()">No Thanks</button>-->
  <button [disabled]="!data.message" mat-button class="blue3 btn-block" [mat-dialog-close]="data.message" cdkFocusInitial>Send</button>
</div>
