import {CUSTOM_ELEMENTS_SCHEMA, NgModule, isDevMode} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {HttpModule} from '@angular/http';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthService} from './services/auth.service';



import {MatDatepickerModule} from '@angular/material/datepicker';

import {AppComponent} from './app.component';

import {SidebarModule} from './sidebar/sidebar.module';
import {FooterModule} from './shared/footer/footer.module';
import {NavbarModule} from './shared/navbar/navbar.module';
import {FixedpluginModule} from './shared/fixedplugin/fixedplugin.module';
import {AdminLayoutComponent} from './layouts/admin/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth/auth-layout.component';

import {AppRoutes} from './app.routing';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule, SETTINGS} from '@angular/fire/firestore';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {environment} from '../environments/environment';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {HttpClientModule} from '@angular/common/http';
import {FieldErrorDisplayComponent} from './components/field-error-display/field-error-display.component';
import {MatIconModule} from '@angular/material/icon';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MAT_DATE_LOCALE, MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Lightbox, LightboxModule} from 'ngx-lightbox';


import { PlatformModule } from '@angular/cdk/platform';
/** Alyle UI */
import {
    LyTheme2,
    StyleRenderer,
    LY_THEME,
    LY_THEME_NAME,
    LY_THEME_GLOBAL_VARIABLES,
    LyHammerGestureConfig, LyStyleDeprecatedModule
} from '@alyle/ui';
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';

import { color } from '@alyle/ui/color';

export class GlobalVariables {
    testVal = color(0x00bcd4);
    Quepal = {
        default: `linear-gradient(135deg,#11998e 0%,#38ef7d 100%)`,
        contrast: color(0xffffff),
        shadow: color(0x11998e)
    };
    SublimeLight = {
        default: `linear-gradient(135deg,#FC5C7D 0%,#6A82FB 100%)`,
        contrast: color(0xffffff),
        shadow: color(0xB36FBC)
    };
    Amber = {
        default: color(0xffc107),
        contrast: color(0, 0, 0, 0.87)
    };
}

import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LySliderModule } from '@alyle/ui/slider';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import {LyToolbarModule} from '@alyle/ui/toolbar';
import {HAMMER_GESTURE_CONFIG, HammerModule} from '@angular/platform-browser';
import { CropperComponent } from './cropper/cropper.component';
import {DialogContentExampleDialog} from './user-profile/dialog-content-example-dialog.component';
import {AmChartsModule} from '@amcharts/amcharts3-angular';
import {AngularFireAuthGuardModule} from '@angular/fire/auth-guard';
import {NgxSpinnerModule} from 'ngx-spinner';
import {EmailDialog} from './downloads/downloads.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import {AngularFireAnalyticsModule} from '@angular/fire/analytics';

export const firebaseConfig = environment.firebaseConfig;


@NgModule({
    exports: [
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatInputModule,
        FieldErrorDisplayComponent,
        LyButtonModule,
        LyToolbarModule,
        LyImageCropperModule,
        // ...
        // Gestures
        HammerModule,
        AmChartsModule,
    ],
    imports: [
        CommonModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: true,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })
    ],


    declarations: [
        FieldErrorDisplayComponent,

    ]
})

export class MaterialModule {}

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        RouterModule.forRoot(AppRoutes, {relativeLinkResolution: 'legacy'}),
        HttpModule,
        HttpClientModule,
        MaterialModule,
        LightboxModule,
        MatNativeDateModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        FixedpluginModule,
        MatFormFieldModule,
        MatInputModule,
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFireAuthGuardModule,
        AngularFireStorageModule,
        AngularFireAnalyticsModule,
        NgxSpinnerModule,
        PlatformModule,
        LySliderModule,
        LyIconModule,
        LyStyleDeprecatedModule,


    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers:[AuthService,
        [ LyTheme2 ],
        [ StyleRenderer ],
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        // Theme that will be applied to this module
        { provide: LY_THEME_NAME, useValue: 'minima-light' },
        { provide: Window, useValue: window },
        // {provide: SETTINGS , useValue: environment.production ? undefined : {
        //         host: 'localhost:8080',
        //         ssl: false
        //     }
        // },
        { provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
        { provide: LY_THEME, useClass: MinimaDark, multi: true }, // name: `minima-dark`
        // Gestures
        { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig }
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        CropperComponent,
        DialogContentExampleDialog,
        EmailDialog

    ],
    entryComponents:[DialogContentExampleDialog,EmailDialog],
    bootstrap:    [ AppComponent ]

})
export class AppModule { }
