<div class="main-content">
    <ngx-spinner fullScreen="true"
                 bdColor="rgba(51,51,51,0.8)"
                 size="medium"
                 color="#fff"
                 type="ball-clip-rotate-multiple">
        <p style="font-size: 20px; color: white"></p>
    </ngx-spinner>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header card-header-primary card-header-icon">
                        <div class="card-icon">
                            <i class="material-icons">assignment</i>
                        </div>
                        <h4 class="card-title">Coc history</h4>
                    </div>
                    <div class="card-body">
                        <div class="toolbar">
                            <!--        Here you can write extra buttons/actions for the toolbar              -->
                            <h3 *ngIf="mobile" class="text-center text-danger ">Swipe sideways for more options</h3>
                            <p class="text-center text-danger ">Please check the information on the coc sample is
                                correct before downloading/paying. COC'S in sample or once downloaded cannot be changed.
                                If the information is incorrect please redo the form</p>

                            <div class="text-center p-4">
                                <button (click)="navG()" class="btn btn-primary btn-sm">Refresh</button>
                            </div>
                        </div>
                        <ais-instantsearch [config]="config">
                            <ais-configure [searchParameters]="{ hitsPerPage: 15,  filters: filters }"></ais-configure>

                            <ais-search-box
                                    placeholder="Search COCs"></ais-search-box>
                            <ais-hits>
                                <ng-template let-hits="hits" let-results="results">
                                    <div class="material-datatables p-1 table-responsive ">

                                        <table class="row-border hover " cellspacing="80" width="100%">
                                            <thead>
                                            <tr>
                                                <th colspan="2"> N#</th>
                                                <th colspan="2">Serial number</th>
                                                <th colspan="2">Plumber</th>
                                                <th colspan="2">Address</th>
                                                <th colspan="2">Submission date</th>
                                                <th colspan="2">Actions</th>
                                            </tr>
                                            </thead>
                                            <ng-container *ngIf="filters">
                                                <div *ngIf="hits.length === 0" class="text-center">
                                                    <p>No results have been found for {{ results?.query }}</p>
                                                </div>
                                                <tbody>

                                                <tr *ngFor="let row  of hits;">

                                                    <td colspan="2">
                                                        <ais-highlight [hit]="row"
                                                                       attribute="count">
                                                        </ais-highlight>
                                                    </td>
                                                    <td colspan="2">
                                                        <p *ngIf="row?.geyser_number">
                                                            <ais-highlight [hit]="row"
                                                                           attribute="geyser_number">
                                                            </ais-highlight>
                                                        </p>
                                                        <p *ngIf="row?.replacement_geyser_serial">
                                                            <ais-highlight [hit]="row"
                                                                           attribute="replacement_geyser_serial">
                                                            </ais-highlight>
                                                        </p>
                                                        <p *ngIf="row?.heat_pump_number">
                                                            <ais-highlight [hit]="row"
                                                                           attribute="heat_pump_number">
                                                            </ais-highlight>
                                                        </p>
                                                        <p *ngIf="row?.replacement_heat_pump_serial">
                                                            <ais-highlight [hit]="row"
                                                                           attribute="replacement_heat_pump_serial">
                                                            </ais-highlight>
                                                        </p>


                                                    </td>
                                                    <td colspan="2"><a [routerLink]="'/profile/'+row?.uid"
                                                    >
                                                        <ais-highlight attribute="name"
                                                                       [hit]="row">
                                                        </ais-highlight>
                                                    </a></td>
                                                    <td colspan="2">
                                                        <ais-highlight [hit]="row"
                                                                       attribute="installation_address">
                                                        </ais-highlight>
                                                    </td>


                                                    <td colspan="2">{{row?.sub_date|date:'medium'}}</td>
                                                    <td colspan="2"><a [routerLink]="row.type + '/' + row.objectID">

                                                        <mat-icon *ngIf="row?.refix == 1" matTooltip="Marked for refix"
                                                                  color="warning" class="material-icons pointer view">
                                                            build
                                                        </mat-icon>
                                                        <mat-icon *ngIf="row?.refix == 3" matTooltip="Suspended"
                                                                  color="warning" class="material-icons pointer view">
                                                            close
                                                        </mat-icon>

                                                        <mat-icon *ngIf="row?.non_compliance == 'No'" color="warn"
                                                                  matTooltip="Non compliant submission"
                                                                  class="material-icons pointer view">warning
                                                        </mat-icon>

                                                        <mat-icon [ngStyle]="{'color':color(row.type)}"
                                                                  matTooltip="{{row.type.substring(1)}}"
                                                                  class="material-icons pointer circle">circle
                                                        </mat-icon>

                                                        <mat-icon color="primary" matTooltip="View"
                                                                  class="material-icons pointer view">visibility
                                                        </mat-icon>
                                                    </a>

                                                    </td>
                                                </tr>


                                                </tbody>
                                            </ng-container>
                                        </table>
                                    </div>
                                    <div class="mat-footer-cell text-center text-primary semi-bold">
                                        <h4>{{results?.nbHits}}: Total Records </h4></div>

                                </ng-template>
                            </ais-hits>
                            <ais-pagination></ais-pagination>
                        </ais-instantsearch>

                    </div>
                    <!-- end content-->
                </div>
                <!--  end card  -->
            </div>
            <!-- end col-md-12 -->
        </div>
        <!-- end row -->
    </div>
</div>
