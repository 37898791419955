import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {NotificationService} from '../services/notification.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {SwalService} from '../services/swal.service';
import {AngularFireStorage} from '@angular/fire/storage';
import {AngularFireAuth} from '@angular/fire/auth';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import Swal from 'sweetalert2';
import {DownloadsService} from '../downloads.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CocService} from '../services/coc.service';
import {HttpClient} from '@angular/common/http';
import {searchClient} from '../submissions/store.component';

declare let $: any;

@Component({
  selector: 'app-service-submissions',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit, OnDestroy {
  public uid: string;
    public subs = [];
    downloads: any;
    private geysers: any;
    dtOptions: any = {
        'ordering': false
    };
    private sub: Subscription;

    auditors: any = ['Marc',
        'Geoff',
        'Darrin',
        'Scott'];
    statuses:any = [
       "Refix",
        "1st Warning",
        '2nd Warning',
        '3rd Warning',
        '*Fixed / Approved',
        '*Suspended',
        'Approved'
    ]
    dtTrigger: Subject<any> = new Subject();
    mobile: boolean;
    private amount: any;
    private user: any;
    config = {
        indexName: 'downloads',
        searchClient,
    };

    message: any;
    pdfLink: any = null;

    constructor(public dialog: MatDialog,
                public afs: AngularFirestore,
                public toast: NotificationService,
                public spinner: NgxSpinnerService,
                private http: HttpClient,
                public s: SwalService,
                private download: DownloadsService,
                private coc: CocService,
                public router: Router,
                public a: AuthService,
                public storage: AngularFireStorage,
                public afAuth: AngularFireAuth) {


    }



    openDialog(row): void {


        if(!row.status  || !row.auditor){
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "Please Select Status or Auditor",
                showConfirmButton: false,
                timer: 1500
            }).then(() => {

                this.spinner.hide()

            })}else {


        const dialogRef = this.dialog.open(EmailDialog, {
          //  width: '1950px',
            data: {message: this.message, },
        });

        dialogRef.afterClosed().subscribe(async text => {

            this.message = text;



            if (text) {
                this.spinner.show().then()
                if (row.status == '1st Warning' || "Refix" || row.status == '2nd Warning' || row.status == '3rd Warning') {

                    await this.afs.collection('submissions').doc(row.objectID).update({refix: 1}).then()
                    // await this.afs.collection('downloads').doc(row.id).update({refix: true}).then()


                } else if (row.status == '*Fixed / Approved' || row.status == 'Approved') {
                    await this.afs.collection('submissions').doc(row.objectID).update({refix: 2}).then()
                } else if (row.status == '*Suspended') {

                    await this.afs.collection('submissions').doc(row.objectID).update({refix: 3}).then()
                }

                this.getCoc(row.objectID).then((coc) => {
                    this.pdfLink = coc;
                })
                this.changeStat(row, text).then()





            }
        });
        }

    }
  ngOnInit() {


      window.onresize = () => this.mobile = window.innerWidth <= 991;

      this.getDownloads().then()
      this.getUid().then()

      this.getDownl();
  }


    async getDownloads() {



    }


    nav(sub: any) {



        this.router.navigate([sub.type + '/' + sub.objectID])
    }







    ngOnDestroy(): void {
     // this.sub.unsubscribe()

    }

    color(type) {
      switch (type){

          case '/view-anode':return'#FD9B19';

          case '/view-prv':return'#53AC57'

          case '/view-vacuum':return'#1CBED2';

          case '/view-element':return'#A844BA'

          case '/view-thermostat':return'#E94643';




      }

    }

    stringify(row: any) {
        return JSON.stringify(row)
    }



    // async changeStatus(row: any) {
    //     // @ts-ignore
    //     const { value: text } = await Swal.fire({
    //         input: 'textarea',
    //         title: 'Send Message',
    //         inputPlaceholder: 'Type your message here...',
    //
    //         inputValidator: (value) => {
    //             if (!value) {
    //                 return 'Please enter a message!'
    //             }
    //         },
    //         showCancelButton: true
    //     })

    //     if (text) {
    //          this.spinner.show().then()
    //         // if (row.status == '*Fixed / Approved'){
    //         //
    //         //    // await this.afs.collection('submissions').doc(row.id).update({refix:true}).then()
    //         //    //  await this.afs.collection('downloads').doc(row.id).update({refix:true}).then()
    //         //
    //         //
    //         // }
    //         this.changeStat(row, text).then()
    //
    //
    //     }
    // }

    async change(row) {


        if (row.status == 'Approved') {
            this.spinner.show().then()
            await this.afs.collection('downloads').doc(row.objectID).update({
                status: row.status,
                auditor: row.auditor || null
            })
            this.spinner.hide().then()

        }


    }



    async getCoc(id) {

        const coc:any = await this.coc.getSingle(id)

        return coc.pdfLink
    }

    async changeStat(row: any,text) {


        await this.afs.collection('downloads').doc(row.objectID).update(row)

           Swal.fire({
               position: 'center',
               icon: 'success',
               title: "Changed Status and message sent to the plumber",
               showConfirmButton: false,
               timer: 1500
           }).then(() => {

               setTimeout(() => {
                   this.spinner.hide()
                   location.reload()

               }, 3000)

           })




           let body = `<h4 style='font-size: 19px;'>WACC COC #  ${row.count}</h4>
        <p style='font-size: 19px;''> ${row.auditor} has been assigned to audit a COC </p>
         <br />
        <br />
      
         <p>${text}  <br />
       
        `

           let data = {
               body: body.toString(),
               email: 'audits@waccsa.co.za',
               subject: 'Wacc COC '  +row.count +" Audit",
               attachment:this.pdfLink
           }




           let body2 = `<h4 style='font-size: 19px;'>WACC COC #  ${row.count}</h4>
        <p style='font-size: 19px;'>Status Changed to ${row.status} </p> <br />
         <p style='font-size: 19px;'>Good day </p>
        
        <br />
        
        <p >${text}  <br />
        <br />
        
         <p>For any auditing enquires please contact <br /><a href='mailto:audits@waccsa.co.za'> audits@waccsa.co.za</a> with your COC attached and your COC number as the subject.</p>
                <p>Kind regards,<br />WACC Auditing Team  <a href='https://waccsa.co.za'> https://waccsa.co.za</a></p>
                
             
        `

           let data2 = {
               body: body2.toString(),
               email: row.email,
               attachment:this.pdfLink,
               cc:'audits@waccsa.co.za',
               subject: 'Wacc COC '  +row.count +" Audit",
           }
           this.a.sendMail(data, 'sendMail').subscribe(() => {
               this.s.show('Success', 'success')
               this.navR()

           });

           this.a.sendMail(data2, 'sendMail').subscribe(() => {
               this.spinner.hide()

           });

    }


    getDownl() {
        this.http.get('https://us-central1-waccsa-e8589.cloudfunctions.net/getTotalDownloads').subscribe((res: any) => {
            this.downloads = res.total
        })
    }

    async getUid() {

        this.user = await this.a.getUser()
        this.uid = this.user.uid
    }

    navG(s: string) {
        window.open(s, '_blank', 'width=1000, height=800')

    }

    navR() {
        location.reload()
    }
}




export interface DialogData {
    message: any;

}



@Component({
    selector: 'email-dialog',
    templateUrl: 'email-dialog.html',
})
export class EmailDialog {

    constructor(
        public dialogRef: MatDialogRef<EmailDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }


}
